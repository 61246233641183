import { Link } from 'bv-components';
import { IMeeting } from '../../../utils/propTypes';

const EventChip = ({ meeting }) => (
  <li key={meeting.id}>
    <Link
      className="bvs-button-chip is-small"
      to={`/sports/1000/events/${meeting.id}`}
    >
      {meeting.events[0].description}
    </Link>
  </li>
);

EventChip.propTypes = {
  meeting: IMeeting.isRequired,
};

export default EventChip;
