import { useFetch } from 'bv-hooks';
import { withModalWindow } from 'bv-hocs';
import { Spinner } from 'bv-components';
import fetchTimetable from './api/fetch_timetable';

import TimeTableHeader from './components/time_table_header';
import TimeTableContents from './components/time_table_contents';

const GreyHoundsTimeTable = () => {
  const [meetings, isLoading] = useFetch(fetchTimetable);

  if (isLoading) return <Spinner />;

  return (
    <>
      <TimeTableHeader />
      <TimeTableContents meetings={meetings} />
    </>
  );
};

export default withModalWindow('timetable.all_races')(GreyHoundsTimeTable);
