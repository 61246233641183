import { useParams } from 'react-router';
import classNames from 'classnames';
import { t } from 'bv-i18n';
import { Link } from 'bv-components';

const TimeTableHeader = () => {
  const { day } = useParams();

  return (
    <div className="greyhounds-modal-filter-wrapper">
      <div className="greyhounds-modal-filter">
        {
          ['today', 'tomorrow', 'future'].map((d) => (
            <Link
              to={`/sports/1000/timetable/${d}`}
              className={classNames('bvs-button-tab', { active: d === day })}
            >
              {t(`timetable.${d}`)}
            </Link>
          ))
        }
      </div>
    </div>
  );
};

export default TimeTableHeader;
