import { useParams } from 'react-router';
import { IMeetingsRequest } from '../../../utils/propTypes';

import EventChip from './event_chip';

const TimeTableContents = ({ meetings }) => {
  const { day } = useParams();

  return (
    <div className="navbar-modal bvs-card">
      {
        Object.values(meetings[day]).map((coll) => (
          <div className="navbar-modal__market">
            <h5 className="navbar-modal__market-title">{ coll[0].description }</h5>
            <ul className="navbar-modal__market-list">
              {
                coll.map((meeting) => <EventChip meeting={meeting} />)
              }
            </ul>
          </div>
        ))
      }
    </div>
  );
};

TimeTableContents.propTypes = {
  meetings: IMeetingsRequest.isRequired,
};

export default TimeTableContents;
